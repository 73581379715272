var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Add Service" } }),
      _c("gov-back-link", { attrs: { to: { name: "services-index" } } }, [
        _vm._v("\n    Back to services\n  ")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "one-half" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Add a new Service Page")
                  ]),
                  _c("gov-body", [
                    _vm._v(
                      "\n          You're about to add a service/club/activity/group to " +
                        _vm._s(_vm.appName) +
                        ".\n          Here are some things you should know:\n        "
                    )
                  ]),
                  _c("gov-list", { attrs: { bullet: "" } }, [
                    _c("li", [
                      _vm._v(
                        "\n            You will be asked to provide information a customer would want to\n            know about your service - make sure you have it to hand\n          "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n            You can add as many services as you want - so please split your\n            services into multiple pages as appropriate\n          "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n            When you are finished your page will be sent to the admin team for\n            approval\n          "
                      )
                    ])
                  ]),
                  _c("gov-warning-text", [
                    _vm._v(
                      "\n          This form will take about 25 minutes to complete. We cannot save\n          your progress, so please make sure you have enough time.\n        "
                    )
                  ]),
                  _c(
                    "gov-button",
                    { attrs: { to: { name: "services-index" } } },
                    [_vm._v("\n          Back to services ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "gov-button",
                    { attrs: { to: { name: "services-create" } } },
                    [_vm._v("\n          Continue\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }